import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ScrollAnimation from "react-animate-on-scroll";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";
import parse from 'html-react-parser';

import NoImage from "../../images/no-image.jpg"
import { NewsDate } from "../common/site/functions"

const SIMILAR_NEWS = gql`
query SIMNEWS($URL: String!){
    allNews(where:{URL_ne: $URL},limit:6){
        id
        Title
        URL
        Tile_Image {
          url
          alternativeText
        }
        imagetransforms
    }
}
`;


function MoreNews(props) {

    const { loading, error, data } = useQuery(SIMILAR_NEWS, {
        variables: { URL: props.slug }
    });

    if (loading) return (
        <p>loading</p>
    )

    var settings = {
        dots: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: data.allNews.length > 3 ? true : false,
        arrows: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: data.allNews.length > 3 ? true : false,
                },
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: data.allNews.length > 3 ? true : false,
                },
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <React.Fragment>
            <section className="guides-section-list more-similar-news">
                <Container>
                    <Row className="head">
                        <Col lg={12}>
                            <h2>May also interest you...</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Slider {...settings} className="guide-tile-slide">
                            {data.allNews && data.allNews.map((item, i) => {
                                const image_url = item.Tile_Image?.url;

                                let processedImages = JSON.stringify({});
                                if (item.imagetransforms?.Tile_Image_Transforms) {
                                    processedImages = item.imagetransforms.Tile_Image_Transforms;
                                }
                                return (
                                    <Link to={`../${item.URL}`}>
                                        <div className="guide">
                                            <div className="img-zoom">
                                                <ImageTransform
                                                    imagesources={image_url}
                                                    renderer="srcSet"
                                                    imagename="all-news.Tile_Image.tile_1"
                                                    attr={{ alt: item.Tile_Image ? item.Tile_Image.alternativeText : item.Title, class: '' }}
                                                    imagetransformresult={processedImages}
                                                    id={item.id}
                                                />
                                                {/* <img src={item.Tile_Image ? item.Tile_Image.url : NoImage} alt={item.Tile_Image ? item.Tile_Image.alternativeText : item.Title} /> */}
                                            </div>
                                            <p className="title"><span>{item.Title}</span></p>
                                            {/* <p className="date">{NewsDate(item.Date)}</p> */}
                                        </div>
                                    </Link>
                                )
                            })}
                        </Slider>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default MoreNews;
